var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"router-view-container"},[_c('div',{staticClass:"flex items-center space-x-4 mb-5"},[_c('vs-button',{staticClass:"flex-shrink-0",attrs:{"border":"","size":"large"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('svg-icon',{attrs:{"icon-class":"arrow_left","className":"primary"}})],1),_c('h1',{staticClass:"page_title"},[_vm._v(_vm._s(_vm.$route.meta.title))])],1),(_vm.is_API_loaded)?_c('main',{staticClass:"space-y-5"},[(!_vm.personalData.isenable)?_c('section',[_c('vs-alert',{attrs:{"color":"danger","solid":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('svg-icon',{attrs:{"icon-class":"caution","className":"xxl"}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("帳號尚未開通")]},proxy:true}],null,false,2030098530)},[_vm._v(" 請至您的 e-mail 檢查有無收到開通信 或 "),_c('button',{ref:"activateBtn",staticClass:"font-medium underline",on:{"click":_vm.sentActivateMail}},[_vm._v("重新寄送開通信")])])],1):_vm._e(),_c('section',{staticClass:"grid grid-cols-1 md:grid-cols-2 gap-6"},[_c('ValidationObserver',{staticClass:"box flex flex-col",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"bigTitle"},[_vm._v("基本資料")]),_c('div',[_c('h1',{staticClass:"title"},[_vm._v("帳號")]),_c('vs-input',{attrs:{"disabled":""},model:{value:(_vm.personalData.account),callback:function ($$v) {_vm.$set(_vm.personalData, "account", $$v)},expression:"personalData.account"}})],1),_c('div',[_c('h1',{staticClass:"title"},[_vm._v("姓名")]),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"姓名","rules":"required|max:20","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{model:{value:(_vm.personalData.uname),callback:function ($$v) {_vm.$set(_vm.personalData, "uname", $$v)},expression:"personalData.uname"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('vs-button',{ref:"savePersonalBtn",staticClass:"self-end",attrs:{"size":"large","disabled":invalid},on:{"click":function($event){return _vm.savePersonal(invalid)}}},[_c('span',{staticClass:"font-medium"},[_vm._v("儲存")])])]}}],null,false,234031332)}),_c('div',{staticClass:"box",staticStyle:{"height":"fit-content"}},[_c('h1',{staticClass:"bigTitle"},[_vm._v("密碼")]),_c('div',{staticClass:"flex items-center justify-center"},[_c('vs-button',{staticClass:"font-medium w-1/3",attrs:{"border":"","size":"large"},on:{"click":function($event){_vm.changePassword_dialog = true}}},[_vm._v("變更密碼")])],1)])],1)]):_vm._e()]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('vs-dialog',{attrs:{"overflow-hidden":"","blur":"","not-center":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',{staticClass:"text-base font-medium text-gray-text_dark"},[_vm._v("變更密碼")])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"flex justify-end space-x-4"},[_c('vs-button',{attrs:{"size":"large","transparent":""},on:{"click":function($event){_vm.changePassword_dialog = false}}},[_vm._v("取消")]),_c('vs-button',{ref:"changePasswordBtn",attrs:{"size":"large","color":"primary","disabled":invalid},on:{"click":function($event){return _vm.changePassword(invalid)}}},[_vm._v("確認")])],1)]},proxy:true}],null,true),model:{value:(_vm.changePassword_dialog),callback:function ($$v) {_vm.changePassword_dialog=$$v},expression:"changePassword_dialog"}},[_c('div',{staticClass:"mb-6 space-y-6"},[_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"目前的密碼","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"目前的密碼","visiblePassword":_vm.visiblePassword},on:{"click-icon":function($event){_vm.visiblePassword = !_vm.visiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.oldPassword === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.visiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('hr'),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"新密碼","rules":"required|password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"新密碼","visiblePassword":_vm.visiblePassword},on:{"click-icon":function($event){_vm.visiblePassword = !_vm.visiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.newPassword === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.visiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"relative",attrs:{"mode":"aggressive","name":"再次確認密碼","rules":"required|confirmed:新密碼","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{attrs:{"type":"password","placeholder":"再次確認密碼","visiblePassword":_vm.visiblePassword},on:{"click-icon":function($event){_vm.visiblePassword = !_vm.visiblePassword}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [(_vm.confirmPassword === '')?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"password"}}):(_vm.visiblePassword)?_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_hide"}}):_c('svg-icon',{staticStyle:{"fill":"black"},attrs:{"icon-class":"eye_view"}})]},proxy:true}],null,true),model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('errorDiv',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }