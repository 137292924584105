<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main v-if="is_API_loaded" class="space-y-5">
        <section v-if="!personalData.isenable">
          <vs-alert color="danger" solid>
            <template #icon>
              <svg-icon icon-class="caution" className="xxl"></svg-icon>
            </template>
            <template #title>帳號尚未開通</template>
            請至您的 e-mail 檢查有無收到開通信 或
            <button class="font-medium underline" @click="sentActivateMail" ref="activateBtn">重新寄送開通信</button>
          </vs-alert>
        </section>

        <section class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <ValidationObserver v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <h1 class="bigTitle">基本資料</h1>
            <div>
              <h1 class="title">帳號</h1>
              <vs-input disabled v-model="personalData.account" />
            </div>
            <div>
              <h1 class="title">姓名</h1>
              <ValidationProvider mode="aggressive" name="姓名" rules="required|max:20" v-slot="{ errors }" tag="div" class="relative">
                <vs-input v-model="personalData.uname" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <!-- <div>
            <h1 class="title">性別</h1>
            <div class="flex items-start space-x-2 p-3">
              <vs-radio v-model="personalData.sex" :val="0">男</vs-radio>
              <vs-radio v-model="personalData.sex" :val="1">女</vs-radio>
            </div>
          </div> -->
            <vs-button class="self-end" size="large" ref="savePersonalBtn" :disabled="invalid" @click="savePersonal(invalid)">
              <span class="font-medium">儲存</span>
            </vs-button>
          </ValidationObserver>

          <div class="box" style="height: fit-content">
            <h1 class="bigTitle">密碼</h1>
            <div class="flex items-center justify-center">
              <vs-button @click="changePassword_dialog = true" border size="large" class="font-medium w-1/3">變更密碼</vs-button>
            </div>
          </div>
        </section>
      </main>
    </div>

    <!-- 變更密碼 dialog -->
    <ValidationObserver v-slot="{ invalid }">
      <vs-dialog overflow-hidden blur v-model="changePassword_dialog" not-center>
        <template #header>
          <h1 class="text-base font-medium text-gray-text_dark">變更密碼</h1>
        </template>

        <div class="mb-6 space-y-6">
          <ValidationProvider mode="aggressive" name="目前的密碼" rules="required|password" v-slot="{ errors }" tag="div" class="relative">
            <vs-input
              type="password"
              v-model="oldPassword"
              placeholder="目前的密碼"
              :visiblePassword="visiblePassword"
              @click-icon="visiblePassword = !visiblePassword"
            >
              <template #icon>
                <svg-icon v-if="oldPassword === ''" icon-class="password" style="fill: black"></svg-icon>
                <svg-icon v-else-if="visiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
              </template>
            </vs-input>
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
          <hr />
          <ValidationProvider mode="aggressive" name="新密碼" rules="required|password" v-slot="{ errors }" tag="div" class="relative">
            <vs-input
              type="password"
              v-model="newPassword"
              placeholder="新密碼"
              :visiblePassword="visiblePassword"
              @click-icon="visiblePassword = !visiblePassword"
            >
              <template #icon>
                <svg-icon v-if="newPassword === ''" icon-class="password" style="fill: black"></svg-icon>
                <svg-icon v-else-if="visiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
              </template>
            </vs-input>
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
          <ValidationProvider
            mode="aggressive"
            name="再次確認密碼"
            rules="required|confirmed:新密碼"
            v-slot="{ errors }"
            tag="div"
            class="relative"
          >
            <vs-input
              type="password"
              v-model="confirmPassword"
              placeholder="再次確認密碼"
              :visiblePassword="visiblePassword"
              @click-icon="visiblePassword = !visiblePassword"
            >
              <template #icon>
                <svg-icon v-if="confirmPassword === ''" icon-class="password" style="fill: black"></svg-icon>
                <svg-icon v-else-if="visiblePassword" icon-class="eye_hide" style="fill: black"></svg-icon>
                <svg-icon v-else icon-class="eye_view" style="fill: black"></svg-icon>
              </template>
            </vs-input>
            <errorDiv>{{ errors[0] }}</errorDiv>
          </ValidationProvider>
        </div>

        <template #footer>
          <div class="flex justify-end space-x-4">
            <vs-button size="large" transparent @click="changePassword_dialog = false">取消</vs-button>
            <vs-button @click="changePassword(invalid)" size="large" color="primary" ref="changePasswordBtn" :disabled="invalid"
              >確認</vs-button
            >
          </div>
        </template>
      </vs-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'Account',
  data() {
    return {
      is_API_loaded: false,
      personalData: {},
      changePassword_dialog: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      visiblePassword: false,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 4.1.2.1 讀取個人資料
    getData() {
      this.$axios({
        url: 'front/set/person/getPersonData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.personalData = res.data.Data
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.1.2.2 修改個人資料
    savePersonal(invalid) {
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.savePersonalBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/person/uPersonData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          uname: this.personalData.uname,
          sex: this.personalData.sex,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          loading.close()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
    // 4.1.2.3 修改個人密碼
    changePassword(invalid) {
      if (invalid) return

      const loading = this.$vs.loading({
        target: this.$refs.changePasswordBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/set/person/uPersonPawd.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
          oldpawd: this.oldPassword,
          newpawd: this.newPassword,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message + '，請重新登入', '', undefined)
          this.oldPassword = ''
          this.newPassword = ''
          this.confirmPassword = ''
          this.changePassword_dialog = false

          // 登出
          this.$store.commit('login/LOG_OUT')
          this.$store.commit('getWebData/LOG_OUT')
          this.$router.replace({
            name: 'login',
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 4.1.2.4 發送帳號開通信件
    sentActivateMail() {
      const loading = this.$vs.loading({
        target: this.$refs.activateBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: 'front/set/person/setAccountEnableMail.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
        },
      }).then((res) => {
        if (res.data.Success) {
          this.$myVS.openNoti(res.data.Message, '', undefined)
          loading.close()
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
          loading.close()
        }
      })
    },
  },
}
</script>

<style scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}
</style>
